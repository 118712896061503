import React from "react";

import Seo from "../seo";

export const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021.png",
};

export const HasuraConSEO = ({ canonicalLink }) => (
  <Seo
    title="HasuraCon’21: An online Hasura GraphQL user conference"
    description="HasuraCon is a free, online conference. Join us for a week of talks and hand-on workshops about pushing the boundaries of GraphQL forward!"
    meta={ogImage}
    canonicalLink={canonicalLink}
  />
);
