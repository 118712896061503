import React from "react";

import { inputOptions } from "../../../../components/hasuracon2021/Schedule/NavigationStrip";
import { RegisterCTA } from "../../../../components/hasuracon2021/Schedule/RegisterCTA";
import { HasuraConSEO } from "../../../../components/hasuracon2021/HasuraConSEO";
import TopBanner from "../../../../components/hasuracon2021/homepage/TopBanner";
import IndivSpeaker from "../../../../components/hasuracon2021/homepage/IndivSpeaker";
import NavPillToggle from "../../../../components/hasuracon2021/homepage/NavPillToggle";
import HeroOverviewPattern from "../../../../components/hasuracon2021/images/hero-overview-pattern.png";
import Layout from "../../../../components/hasuracon2021/Layout";
import interestedSpeakerGraphic from "../../../../components/hasuracon2021/images/interested-speaker-graphic.png";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/speakers/";

const HasuraCon = ({ location, pageContext }) => {
  const { presenters } = pageContext;
  const [filter, setFilter] = React.useState("conference");

  const speakersList = presenters
    .filter(p => !p.frontmatter.presenter_slug.includes("placeholder"))
    .sort((p1, p2) => p1.frontmatter.position - p2.frontmatter.position)
    .filter(p => {
      if (filter === "workshop") {
        return p.talkTypes.includes("workshops");
      }

      if (filter === "topic") {
        return p.talkTypes.includes("topics");
      }

      return !p.talkTypes.includes("workshops") && !p.talkTypes.includes("topics");
    })
    .map((presenter, index) => {
      return (
        <IndivSpeaker
          key={index}
          speaker={presenter}
          entry={index}
          base="/events/hasura-con-2021/speakers"
        />
      );
    });

  return (
    <Layout
      location={location}
      heroGraphic={() => (
        <img
          className="heroBanRightPattern"
          loading="lazy"
          src={HeroOverviewPattern}
          alt="Speakers Graphic"
        />
      )}
      sideBarGraphic={() => (
        <img
          className="leftInterested"
          src={interestedSpeakerGraphic}
          alt="Graphic"
          width="166px"
        />
      )}
    >
      <HasuraConSEO canonicalLink={canonicalUrl} />
      <TopBanner
        location={location}
        title="Speakers"
        variant="simple"
        footer={() => <RegisterCTA />}
      >
        We've got an incredible line-up of speakers from all over the world for HasuraCon’21. The
        line-up will include engineers from Hasura and users from enterprise/startup.
      </TopBanner>
      <NavPillToggle handleChange={setFilter} options={inputOptions} filter={filter} />
      <div id="speakers" className="speakersWrapper heavyFooter">
        <div className="speakersWrapperOverflow">
          <div className="speakersListWrapper denseGrid">{speakersList}</div>
        </div>
      </div>
    </Layout>
  );
};

export default HasuraCon;
