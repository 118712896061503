import React from "react";

const NavPillToggle = ({ options, handleChange, filter, navPillWd }) => {
  const internalHandleChange = e => {
    e.preventDefault();
    handleChange(e.target.value);
  };

  return (
    <div className={"htcNavPill" + ((navPillWd) ? ' htcNavPillWd' : '')}>
      <form>
        <fieldset>
          <div className="buttons">
            {options.map((v, i) => (
              <label key={i} htmlFor={v.value} className={filter === v.value ? "active" : ""}>
                <p>{v.label}</p>
              </label>
            ))}
          </div>
          <div className="radios">
            {options.map((v, i) => (
              <input
                key={i}
                id={v.value}
                type="radio"
                name="nav-pill"
                value={v.value}
                onChange={internalHandleChange}
                checked={filter === v.value}
              />
            ))}
          </div>
        </fieldset>
        <svg width={((navPillWd) ? 280 : 420)} height={40} viewBox={((navPillWd) ? "0 0 280 40" : "0 0 420 40")} fill="none">
          <rect
            x={0.25}
            y={0.25}
            width={((navPillWd) ? 279.5 : 419.5)}
            height={39.5}
            rx={19.75}
            stroke="#1EB4D4"
            strokeWidth={0.5}
          />
          <rect
            width={140}
            height={40}
            rx={20}
            fill="#1EB4D4"
            x={filter === "workshop" ? 140 : filter === "topic" ? 280 : 0}
          />
        </svg>
      </form>
    </div>
  );
};

export default NavPillToggle;
