import React from "react";
import { Link } from "gatsby";

const IndivSpeaker = ({ speaker, entry, base }) => {
  const { frontmatter } = speaker;
  const { name, image, title, company, presenter_slug } = frontmatter;
  return (
    <Link to={`${base}/${presenter_slug}/`}>
      <div className={`speakersList frameBg frameBg${(entry % 6) + 1}`}>
        <div className="speakersListContainer">
          <div className="speakersImg">
            <img src={image.childImageSharp.original.src} alt={name} />
          </div>
          <div className="speakersProfileWrapper">
            <div className="commonLink">{name}</div>
            <div className="articleDesc fontBold500">
              <span>
                {title},<br /> {company}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default IndivSpeaker;
