import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

// import { AnnouncementBanner } from "../homepagenew/AnnouncementBanner";
import discord from "./images/discord.svg";
import linkedin from "./images/linkedin.svg";
import twitter from "./images/twitter.svg";
import Footer from "./Footer";
import LeftSidebar from "./LeftSidebar";
import "./styles.scss";

function openMenuBar() {
  var x = document.getElementById("navbar");
  var hamburgerMenu = document.getElementById("menuClick");
  if (x.className === "topnav") {
    x.className += " responsive";
    hamburgerMenu.className += " open";
    document.body.style.overflow = "hidden";
    document.getElementById("viewport").style.overflow = "hidden";
  } else {
    if (x.className === "topnav responsive") {
      x.className = "topnav";
      hamburgerMenu.className = "navBarToggle";
      document.body.style.overflow = null;
      document.getElementById("viewport").style.overflow = null;
    }
  }
}

// ******************************* //

const Layout = ({
  children,
  location,
  hideFooterGraphic,
  sideBarGraphic,
  heroGraphic,
  isRegisterDone,
  isMarketingWebsite,
}) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      var x = document.getElementById("navbar");
      if (x.className === "topnav responsive") {
        x.className = "topnav";
        document.body.style.overflow = null;
        document.getElementById("viewport").style.overflow = null;
      }
    }
  };

  const path = location.pathname;
  const isRegister = path === "/events/hasura-con-2021/register/" ? true : false;

  if (isMarketingWebsite) {
    return (
      <div id="viewport" className="hasuraCon2021Wrapper patternWrapper hasuraCon2021WrapperBg">
        {children}
      </div>
    );
  }

  const isTalksPage = path === "/events/hasura-con-2021/talks/" ? true : false;
  const isTalkDetail = path.startsWith("/events/hasura-con-2021/talks/") ? true : false;

  return (
    <div>
      {/* <div>
        <AnnouncementBanner />
      </div>
      */}
      <div
        id="viewport"
        className={`hasuraCon2021Wrapper patternWrapper ${
          hideFooterGraphic ? "" : "hasuraCon2021WrapperBg"
        }`}
      >
        {heroGraphic && heroGraphic()}
        {sideBarGraphic && sideBarGraphic()}
        <div
          className={
            "mainWrapper" +
            (isRegister ? " removePadBottom" : "") +
            (isTalkDetail && !isTalksPage ? " talksBg" : "")
          }
        >
          <div id="navbar" className="topnav" ref={wrapperRef}>
            {/*eslint-disable-next-line*/}
            <div
              className="navBarToggle"
              aria-label="button"
              role="button"
              tabIndex="0"
              onClick={() => openMenuBar()}
              id="menuClick"
            >
              <span className="iconBar"></span>
              <span className="iconBar"></span>
              <span className="iconBar"></span>
            </div>
            <div className="visibleMobile">
              <ul className="navBarULMobile articleDesc">
                {/* <li className="registerActive">
                <Link to="/events/hasura-con-2021/watch/" onClick={() => openMenuBar()}>
                  Start Watching
                </Link>
              </li> */}
                {/* <li>
                <Link to="/events/hasura-con-2021/register/" onClick={() => openMenuBar()}>
                  Register Now
                </Link>
              </li> */}
                <li className="schedule-nav-item">
                  <Link activeClassName="active" to="/events/hasura-con-2021/recordings/">
                    View the recordings
                  </Link>
                </li>
                <li>
                  <Link to="/events/hasura-con-2021/schedule/" onClick={() => openMenuBar()}>
                    Schedule
                  </Link>
                </li>
                <li>
                  <Link to="/events/hasura-con-2021/speakers/" onClick={() => openMenuBar()}>
                    Speakers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/events/hasura-con-2021/talks/"
                    onClick={() => openMenuBar()}
                    state={{ filter: "conference" }}
                  >
                    Talks
                  </Link>
                </li>
                <li>
                  <Link
                    to="/events/hasura-con-2021/talks/"
                    onClick={() => openMenuBar()}
                    state={{ filter: "workshop" }}
                  >
                    Workshops
                  </Link>
                </li>
                <li>
                  <a
                    href="https://hasura.io/events/hasura-con-2021/#topics"
                    onClick={() => openMenuBar()}
                  >
                    Topic Tables
                  </a>
                </li>
                <li>
                  <Link to="/events/hasura-con-2021/jobs/" onClick={() => openMenuBar()}>
                    Jobs
                  </Link>
                </li>
                <li>
                  <a
                    href="https://hasura.io/events/hasura-con-2021/#sponsors"
                    onClick={() => openMenuBar()}
                  >
                    Sponsors
                  </a>
                </li>
                <li>
                  <div className="socialIconWrapper">
                    <a
                      href="https://discord.com/invite/hasura"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={discord} alt="Discord" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/hasura"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={linkedin} alt="Linkedin" />
                    </a>
                    <a
                      href="https://twitter.com/hasurahq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twitter} alt="Twitter" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {isRegisterDone ? (
            <React.Fragment>{children}</React.Fragment>
          ) : (
            <div className="mainGridWrapper">
              <div>
                <LeftSidebar location={location} />
              </div>
              <div>{children}</div>
              <Footer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
