import React from "react";
import { Link } from "gatsby";

import poweredByHasura from "./images/poweredbyhasura.svg";
import "./styles.scss";

const Footer = () => {
  return (
    <div className="footerWrapper removePaddBottom">
      <div>
        <ul className="articleDesc">
          <li>
            <Link to="/events/hasura-con-2021/code-of-conduct/">Code Of Conduct</Link>
          </li>
          <li>
            <Link to="/events/hasura-con-2021/#sponsors">Sponsors</Link>
          </li>
          <li>
            <a href="mailto:events@hasura.io">Contact us</a>
          </li>
        </ul>
        <div className="articleDesc">
          © {new Date().getFullYear()} Hasura Inc. All rights reserved
        </div>
      </div>
      <div className="footerLogoWrapper">
        <div className="poweredLogo">
          <a href="https://hasura.io/">
            <img src={poweredByHasura} alt="Powered Logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
