import React from "react";
import { Link } from "gatsby";

import "../styles.scss";

const TopBanner = ({
  location,
  ctaText,
  ctaAction,
  title,
  children,
  variant,
  footer,
  isWatchPage,
}) => {
  const path = location.pathname;

  const isHomePage = path === "/events/hasura-con-2021" || path === "/events/hasura-con-2021/";

  return (
    <section className={`patternWrapper`}>
      <div
        className={`topBannerWrapper sectionWrapper removePaddTop ${
          variant === "simple" ? "" : "borderBottom"
        } ${isHomePage ? " removePaddTop" : ""}`}
      >
        <h1 className="title">{title}</h1>
        {/* Marketo form */}
        <div className={isWatchPage || isHomePage ? " addWatchPadding articleDesc" : "articleDesc"}>
          {children}
        </div>
        {ctaAction && (
          <div className="buttonWrapper">
            <Link to={ctaAction}>
              <button className="hasuraConCommonAction hasuraConBlueBtn">{ctaText}</button>
            </Link>
          </div>
        )}
        {footer && footer()}
      </div>
    </section>
  );
};

export default TopBanner;
