import React, { Fragment, useEffect } from "react";
import { Link } from "gatsby";

import hasuraConBrand from "./images/hasura-con-brand.gif";
import discord from "./images/discord.svg";
import linkedin from "./images/linkedin.svg";
import twitter from "./images/twitter.svg";
import "./styles.scss";

export const scrollToTop = () => {
  if (typeof window !== undefined) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
};

const LeftSidebar = ({ location, isRegisterDone }) => {
  const pathHash = location.hash;
  const pathName = location.pathName;
  const filter = location.state?.filter;

  useEffect(() => {
    if (pathHash !== "#topics" && pathHash !== "#sponsors") {
      scrollToTop();
    }
  }, [pathHash, pathName, filter]);

  const getNavigationLinks = () => {
    if (isRegisterDone) {
      return null;
    }

    return (
      <Fragment>
        <ul className="articleDesc">
          <li className="schedule-nav-item">
            <Link activeClassName="active" to="/events/hasura-con-2021/recordings/">
              View the recordings
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/events/hasura-con-2021/schedule/">
              Schedule
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/events/hasura-con-2021/speakers/">
              Speakers
            </Link>
          </li>
          <li>
            <Link
              activeClassName="active"
              to="/events/hasura-con-2021/talks/"
              state={{ filter: "conference" }}
            >
              Talks
            </Link>
          </li>
          <li>
            <Link
              activeClassName="active"
              to="/events/hasura-con-2021/talks/"
              state={{ filter: "workshop" }}
            >
              Workshops
            </Link>
          </li>
          <li>
            <Link
              activeClassName="active"
              to="/events/hasura-con-2021/talks/"
              state={{ filter: "topic" }}
            >
              Topic Tables
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/events/hasura-con-2021/jobs/">
              Jobs
            </Link>
          </li>
          <li>
            <a
              href="https://hasura.io/events/hasura-con-2021/#sponsors"
              className={pathHash === "#sponsors" ? "active" : ""}
            >
              Sponsors
            </a>
          </li>
        </ul>
        <div className="leftSidebarFooterWrapper">
          <div className="socialIconWrapper">
            <a href="https://discord.com/invite/hasura" target="_blank" rel="noopener noreferrer">
              <img src={discord} alt="Discord" />
            </a>
            <a
              href="https://www.linkedin.com/company/hasura"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="Linkedin" />
            </a>
            <a href="https://twitter.com/hasurahq" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className="leftSidebarWrapper">
      <div className="h2c-brand">
        <Link to="/events/hasura-con-2021/">
          <img src={hasuraConBrand} alt="Brand" />
        </Link>
        <div className="articleDescSmall">Jun 23rd - 24th 2021</div>
      </div>
      {getNavigationLinks()}
    </div>
  );
};

export default LeftSidebar;
