import React, { useState, useEffect } from "react";

import NavPillToggle from "../homepage/NavPillToggle";
import "../styles.scss";

export const inputOptions = [
  {
    label: "Conference",
    value: "conference",
  },
  {
    label: "Workshops",
    value: "workshop",
  },
  {
    label: "Topic Tables",
    value: "topic",
  },
];

export const NavigationStrip = ({ filter, activeDay, setFilter, setActiveDay }) => {
  const [scrollValue, setScrollValue] = useState(null);

  useEffect(() => {
    if (typeof window !== undefined) {
      function handleScroll() {
        setScrollValue(window.pageYOffset);
      }

      window.addEventListener("scroll", handleScroll);

      handleScroll();

      return () => window.removeEventListener("resize", handleScroll);
    }
  }, []);

  const handleDay = day => {
    const dayHeadingElementID = day === 23 ? "day1-wednesday" : "day2-thursday";

    setActiveDay(day);
    document
      .getElementById(dayHeadingElementID)
      .scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
  };

  const isBlurEffectActive = scrollValue && scrollValue > 30;

  return (
    <div className={isBlurEffectActive ? "scheduleNavStrip addBlurEffect" : "scheduleNavStrip"}>
      <button
        className={activeDay === 23 ? "active-schedule-day schedule-day" : "schedule-day"}
        onClick={() => handleDay(23)}
      >
        Wednesday, June 23
      </button>
      <button
        className={activeDay === 24 ? "active-schedule-day schedule-day" : "schedule-day"}
        onClick={() => handleDay(24)}
      >
        Thursday, June 24
      </button>
      <NavPillToggle handleChange={setFilter} options={inputOptions} filter={filter} />
    </div>
  );
};
